import React, { useState, useEffect } from "react";
import "./layout.scss";
import SingleAd from "./SingleAd";
import QusAns from "../chat-area/QusAns";
import { Header, Header2 } from "./Header";
import Login from "./Auth/Login";
import { SideMenu, WithoutLoginBlock } from "./SideMenu";
import contextList from "../utils/static-category.json";
import { PC_API_LINK } from "../utils/globalFunctions";

const Layout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [history, setHistory] = useState([]);

  const [clickedId, setClickedId] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [chatData, setChatData] = useState([]);

  const [contextIndex, setContextIndex] = useState(0);
  const [qusContext, setQusContext] = useState("");
  const [contextType, setContextType] = useState("API");

  const getChatHistory = async (type) => {
    const url = `${PC_API_LINK}/get_chat_history_label/`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: localStorage?.getItem("email"),
          type: type && type !== undefined ? type : "API",
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response?.status}`);
      }
      const jsonResponse = await response.json();
      setHistory(jsonResponse?.chat_history);
    } catch (error) {
      console.error("Error fetching API:", error);
    }
  };

  const getSessionData = () => {
    const userData = localStorage.getItem("user");
    const expiration = localStorage.getItem("expiration");
    const now = new Date().getTime();
    if (expiration && now > expiration) {
      localStorage.removeItem("user");
      localStorage.removeItem("expiration");
      localStorage.removeItem("sessionId");
      localStorage.removeItem("email");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("client");
      localStorage.removeItem("level");
      return null;
    }
    return userData ? JSON.parse(userData) : null;
  };

  useEffect(() => {
    const userLoggedIn = localStorage?.getItem("email");
    getSessionData();
    if (userLoggedIn) {
      getChatHistory(contextType);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    getChatHistory(contextType);
  }, [contextIndex]);

  const loginCheck = () => {
    !localStorage?.getItem("email")
      ? setIsLoggedIn(true)
      : setIsLoggedIn(false);
  };

  return (
    <>
      <main className="">
        <Header
          setIsLoggedIn={setIsLoggedIn}
          setChatData={setChatData}
          setClickedId={setClickedId}
        />
        <Header2 />
        <div className="ai-pc d-flex px-0 w-100">
          <aside className="ai-query-history px-lg-3 px-md-2 px-sm-2">
            <div className="side-menu-link d-flex flex-column h-100 position-relative">
              <h3 className="mb-2 theme-color">Category</h3>
              <div className="use-context">
                {contextList?.contextList?.map((item, index) => (
                  <button
                    aria-label="context name"
                    key={index}
                    className={`${
                      item?.accessFortarun === true &&
                      item?.accessForOthers === true
                        ? ""
                        : item?.accessFortarun === false ||
                          item?.accessForOthers === false
                        ? "coming-soon"
                        : "coming-soon"
                    }${index === contextIndex ? " active" : ""} `}
                    type="text"
                    disabled={
                      item?.accessFortarun === true &&
                      item?.accessForOthers === true
                        ? false
                        : item?.accessFortarun === false ||
                          item?.accessForOthers === false
                        ? true
                        : true
                    }
                    onClick={() => {
                      loginCheck();
                      setContextIndex(index);
                      setQusContext(item?.contextName);
                      setContextType(item?.type);
                      setChatData([]);
                      setClickedId("");
                    }}
                  >
                    {item?.contextDisplayName}
                  </button>
                ))}
              </div>

              <div className="search-history mt-auto">
                {/* {localStorage?.getItem("email") ? (
                <ExpandCollapse
                  title="Search History"
                  fetchHistory={fetchHistory}
                >
                  {history?.map((query, index) => (
                    <p
                      key={index}
                      onClick={() =>
                        historyChat(query?.chat_id, index, contextType)
                      }
                      title={query?.chat_name}
                      className={`mb-1 px-0 text-truncate cursor-pointer px-2 ${
                        index === activeIndex ? "active" : ""
                      }`}
                    >
                      {query?.chat_name}
                    </p>
                  ))}
                </ExpandCollapse>
              ) : (
                <WithoutLoginBlock />
              )} */}
                <WithoutLoginBlock />
              </div>
            </div>
          </aside>
          <div className="ai-responses-area border-start-0 border-end-0 ">
            <QusAns
              chatData={chatData}
              clickedId={clickedId}
              qusContext={qusContext}
              contextType={contextType}
              setHistory={setHistory}
              history={history}
              setActiveIndex={setActiveIndex}
              setChatData={setChatData}
              setClickedId={setClickedId}
            />
          </div>
          <SingleAd />
        </div>
      </main>
      {isLoggedIn && (
        <Login isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      )}
    </>
  );
};
export default Layout;
