import React, { useState } from "react";
import { Link } from "react-router-dom";
import Tooltip from "../../components/ui-component/Tooltip";
import "../qus-ans.scss";
import {
  AnsCopy,
  AtSign,
  Check,
  Money,
  Regenerate,
  ShareAns,
  XlsFileIcon,
} from "../../components/ui-component/SvgIcon";

export const RegenarateBtn = ({ isFetching, triggerFunction, usr_qus }) => {
  return (
    <>
      {/* <Tooltip
        content={"Regenrate"}
        position="bottom"
        fullWidth="w-auto"
        nowrap="nowrap"
      > */}
        <button
          aria-label="regenerate"
          disabled={isFetching}
          className={`btns-5 ms-1 ${isFetching ? "isDisabled" : ""}`}
          onClick={() => {
            const fakeEvent = { preventDefault: () => {} };
            triggerFunction(fakeEvent, usr_qus);
          }}
        >
          <Regenerate />
        </button>
      {/* </Tooltip> */}
    </>
  );
};
export const CopyAns = ({ isFetching, message }) => {
  const [copyStatus, setCopyStatus] = useState(false);

  function copyToClipboard(text) {
    navigator?.clipboard
      .writeText(text)
      .then(() => {})
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
    setCopyStatus(true);
    setTimeout(() => {
      setCopyStatus(false);
    }, 2000);
  }

  return (
    <>
      {/* <Tooltip
        content={copyStatus ? "Copied" : "Copy"}
        position="bottom"
        fullWidth="w-auto"
      > */}
        <button
          aria-label="copy answer"
          disabled={isFetching}
          className={`btns-5 ms-1 ${isFetching ? "isDisabled" : ""}`}
          onClick={() => copyToClipboard(message)}
        >
          {copyStatus ? <Check /> : <AnsCopy />}
        </button>
      {/* </Tooltip> */}
    </>
  );
};
export const DownloadExcel = ({
  hasTable,
  handleDownloadOrShare,
  downloadExcel,
  isFetching,
}) => {
  return (
    <>
      <div className={`${hasTable ? "hide" : ""}`}>
        {/* <Tooltip
          content={"Download Excel"}
          position="bottom"
          fullWidth="w-auto"
          nowrap="nowrap"
        > */}
          <button
            aria-label="xls download"
            className={`btns-5 ms-1 ${hasTable === false ? "isDisabled" : ""} ${
              isFetching ? "isDisabled" : ""
            }`}
            onClick={() => downloadExcel()}
          >
            <XlsFileIcon />
          </button>
        {/* </Tooltip> */}
      </div>
    </>
  );
};
export const ApiRef = () => {
  return (
    <button
      disabled={true}
      className={`btns-5 ms-1 isDisabled`}
      onClick={() => console.log()}
    >
      <Money />
    </button>
  );
};
// export const Mailers = ({ usr_qus, message }) => {
//   return (
//     <Link
//       to={`mailto:support@pharmacompass.com?subject=${encodeURIComponent(
//         usr_qus
//       )}&body=${encodeURIComponent(message)}`}
//     >
//       <button
//         disabled={true}
//         className={`btns-5 ms-1 isDisabled`}
//         onClick={() => console.log()}
//       >
//         <img
//           src={Mailer}
//           alt={`button-img`}
//           className=""
//           height={22}
//           width={22}
//         />
//       </button>
//     </Link>
//   );
// };

export const Mailers = ({ usr_qus, message, setAlertPopup, isFetching }) => {
  return (
    // <Tooltip
    //   content={"Send query"}
    //   position="bottom"
    //   fullWidth="w-auto"
    //   nowrap="nowrap"
    // >
    <button
      aria-label="At sign"
      disabled={true}
      className={`btns-5 ms-1 isDisabled ${isFetching ? "isDisabled" : ""}`}
      // onClick={() => setAlertPopup(true)}
    >
      <AtSign />
    </button>
    // </Tooltip>
  );
};
export const ShareMsg = ({ handleDownloadOrShare, isFetching }) => {
  return (
    <button
      aria-label="share answer"
      disabled={false}
      className={`btns-5 ms-1 isDisabled ${isFetching ? "isDisabled" : ""}`}
      // onClick={() => handleDownloadOrShare(true)}
      onClick={() => console.log()}
    >
      <ShareAns />
    </button>
  );
};
