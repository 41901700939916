import React, { useEffect, useState } from "react";
import { PC_API_LINK } from "../utils/globalFunctions";
import {
  Closeicon,
  HistoryIcon,
  NewChatIcon,
} from "../components/ui-component/SvgIcon";
import { OutlinedButton } from "../components/ui-component/buttons/ButtonType";

const SearchHistory = ({ history, setHistory, setChatData, setClickedId }) => {
  const [fetchHistory, setFetchHistory] = useState(false);

  const historyChat = async (chatId, index, type) => {
    if (chatId !== undefined) {
      setClickedId(chatId);
      const url = `${PC_API_LINK}/getchatdetails/`;
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            chat_id: chatId,
            type: type && type !== undefined ? type : "API",
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const jsonResponse = await response.json();
        setChatData(jsonResponse);
        setFetchHistory(false);
      } catch (error) {
        console.error("Error fetching API:", error);
      }
    }
  };
  const Resetchat = () => {
    setFetchHistory(false);
    setChatData([]);
    setClickedId("");
  };

  useEffect(() => {
    const userLoggedIn = localStorage?.getItem("email");
    if (userLoggedIn) {
      historyChat();
    }
  }, []);

  return (
    <>
      <div className="d-flex mx-auto align-items-center">
        <div
          className="d-flex align-items-center me-3 cursor-pointer gap-1"
          onClick={() => setFetchHistory(true)}
        >
          <HistoryIcon />
          <span className="theme-color fs-14 m-0 p-0">History</span>
        </div>
        <div
          className="d-flex align-items-center me-3 cursor-pointer gap-1"
          onClick={Resetchat}
        >
          <NewChatIcon />
          <span className="theme-color fs-14 m-0 p-0 nowrap"> New Chat</span>
        </div>
      </div>
      {fetchHistory && fetchHistory?.length !== 0 ? (
        <>
          <div className="related-search-result">
            <h2 className="mb-4">Search History</h2>
            <button
              aria-label="close"
              className="close-login hover-change"
              onClick={() => setFetchHistory(false)}
            >
              <Closeicon />
            </button>
            <div className="search-container">
              {/* {history?.map((query, index) => (
                <OutlinedButton
                  key={index}
                  buttonText={query?.chat_name}
                  mxWid={true}
                  clickFunc={() => historyChat(query?.chat_id, query?.type)}
                />
              ))} */}
              {Object?.entries(history)?.map(([date, chats], index) => (
                <div key={date} className="last">
                  <h4 className="pt-2 mb-2">{date}</h4>
                  {chats?.map((chat) => (
                    <OutlinedButton
                      key={index}
                      buttonText={chat?.chat_name}
                      mxWid={true}
                      clickFunc={() => historyChat(chat?.chat_id)}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div
            className="search-overlay"
            onClick={() => setFetchHistory(!setFetchHistory)}
          ></div>
        </>
      ) : null}
    </>
  );
};

export default SearchHistory;
