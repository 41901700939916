import React from "react";

const AssistantLoader = () => {
  return (
    <div className="d-flex align-items-baseline">
      Assistant is typing
      <span className="bouncing-loader ms-2">
        <div></div>
        <div></div>
        <div></div>
      </span>
    </div>
  );
};
export default AssistantLoader;
