import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './ErrorBoundry';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);
// if ('serviceWorker' in navigator) {
//   window?.addEventListener('load', () => {
//     navigator?.serviceWorker?.register('/service-worker.js')
//       .then(registration => {
//         console.log('Service Worker registered with scope:', registration?.scope);
//       })
//       .catch(err => {
//         console.error('Service Worker registration failed:==', err);
//       });
//   });
// }
reportWebVitals();
