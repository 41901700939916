import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import "./header.css";
// import Tooltip from "./ui-component/Tooltip";
import {
  ArrowDown,
  LogoutIcon,
  // ChevDown,
  // NewChatIcon,
  // UserIcon,
} from "./ui-component/SvgIcon";
export const Header = ({ setIsLoggedIn, setChatData, setClickedId }) => {
  const LogoutSession = () => {
    localStorage.removeItem("sessionId");
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("client");
    localStorage.removeItem("level");
    localStorage.setItem("logout", Date.now());
    window.location.reload();
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event?.key === "logout") {
        window.location.href = "/chatbot";
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <header className="border-bottom-0 px-0 py-2">
        <div className="header-1 d-flex align-items-center justify-content-between">
          <img
            src="https://www.pharmacompass.com/dev/image/vb/pharmacompass-white.png?v=4"
            alt="logo"
            height={52}
          />
          <div className="d-flex">
            <Link to="#" className="text-decoration-none mx-3">
              {localStorage.getItem("email") ? (
                <div className="btn-groun position-relative">
                  <button
                    className="login d-flex gap-2 px-2"
                    aria-label="username"
                  >
                    <span
                      className="d-block text-truncate"
                      style={{ width: 80 }}
                    >
                      {localStorage?.getItem("firstName")
                        ? localStorage?.getItem("firstName")
                        : `User`}
                    </span>
                  </button>
                  <button
                    aria-label="logout"
                    className="login d-flex gap-2 px-2 align-items-center"
                    title="Logout"
                    onClick={LogoutSession}
                  >
                    <LogoutIcon color={"var(--white)"} />
                    {/* <ArrowDown color={"var(--white)"} /> */}
                  </button>
                  {/* <div className="login-dropdown">
                  <ul className="m-0 p-0 unstyled-list d-flex flex-column gap-1">
                    <li className="d-flex gap-2">
                      <UserIcon/>
                      <>
                        {localStorage?.getItem("firstName")}{" "}
                        {localStorage?.getItem("lastName")}
                      </>
                    </li>
                    <li className="d-flex gap-2">
                      <LogoutIcon /> Logout
                    </li>
                  </ul>
                </div> */}
                </div>
              ) : (
                <button
                  aria-label="Login"
                  className="login"
                  onClick={() => {
                    setIsLoggedIn(true);
                  }}
                >
                  Login
                </button>
              )}
            </Link>
            <Link to="#" target="_blank" className="">
              <button
                aria-label="pc coins"
                className="login isDisabled"
                disabled
              >
                PharmaPoints
              </button>
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

export const Header2 = ({ setIsLoggedIn, setChatData, setClickedId }) => {
  const [menuServiceHtml, setServiceMenuHtml] = useState("");
  const [expMenuHtml, setExpMenuHtml] = useState("");
  const [pipMenuHtml, setPipMenuHtmlMenuHtml] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const menuRef = useRef(null);
  useEffect(() => {
    const handleHover = (event) => {
      const liElement = event.currentTarget;
      const subMenu = liElement.querySelector("ul");

      if (subMenu) {
        const yOffset =
          parseFloat(liElement.getBoundingClientRect().top) + 32 + "px";
        console.log("yOffset", yOffset);
        subMenu.style.top = yOffset;

        const checkHeightUL = parseFloat(subMenu?.offsetHeight) + 5;
        if (checkHeightUL > 299) {
          subMenu.style.bottom = "0px";
        } else {
          subMenu.style.bottom = "auto";
        }
      }
    };

    const topLevelItems = document.querySelectorAll(
      ".Dis-mob > ul > div > li "
    );
    const secondLevelItems = document.querySelectorAll(
      ".Dis-mob > ul > div > li > ul > li"
    );

    topLevelItems.forEach((item) => {
      item.addEventListener("mouseenter", handleHover);
      item.addEventListener("mouseleave", () => {
        const subMenu = item.querySelector("ul");
        if (subMenu) subMenu.style.bottom = "auto";
      });
    });

    secondLevelItems.forEach((item) => {
      item.addEventListener("mouseenter", handleHover);
      item.addEventListener("mouseleave", () => {
        const subMenu = item.querySelector("ul");
        if (subMenu) subMenu.style.bottom = "auto";
      });
    });

    return () => {
      topLevelItems.forEach((item) => {
        item.removeEventListener("mouseenter", handleHover);
        item.removeEventListener("mouseleave", handleHover);
      });
      secondLevelItems.forEach((item) => {
        item.removeEventListener("mouseenter", handleHover);
        item.removeEventListener("mouseleave", handleHover);
      });
    };
  });
  useEffect(() => {
    fetch("/menu.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setServiceMenuHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, []);
  useEffect(() => {
    fetch("/pipeline-prospector.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setPipMenuHtmlMenuHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, []);
  useEffect(() => {
    fetch("/menu-excipients.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        setExpMenuHtml(data);
      })
      .catch((error) => {
        console.error("Error fetching the file:", error);
      });
  }, []);
  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveIndex(null); // Close the menu if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const showHideMenu = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <header className="mb-4 bg-white" ref={menuRef}>
        <div className="header-1 header-2 d-flex align-items-center">
          <div className="menuInnerHeader">
            <ul className="d-flex p-0 m-0 unstyled-list gap-3">
              <li
                className={`face-menu Dis-mob single-menu-ul ${
                  activeIndex === 1 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(1)}
              >
                Find Suppliers : API/FDF <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list Dis-mob FS-Disable single-menu">
                <li className="first-kind"><a rel="noreferrer" href="https://www.pharmacompass.com/find-suppliers" target="_blank">Search Our Integrated Database </a></li>
                  </ul>
              </li>
              <li
                className={`face-menu Dis-mob ${
                  activeIndex === 2 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(2)}
              >
                Services <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list">
                  <div dangerouslySetInnerHTML={{ __html: menuServiceHtml }} />
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob ${
                  activeIndex === 3 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(3)}
              >
                Excipients <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list hide">
                  <div dangerouslySetInnerHTML={{ __html: expMenuHtml }} />
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob ${
                  activeIndex === 4 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(4)}
              >
                Drugs in Dev. <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list hide">
                  <div dangerouslySetInnerHTML={{ __html: pipMenuHtml }} />
                </ul>
              </li>
              <li
                className={`face-menu Dis-mob single-menu-ul ${
                  activeIndex === 5 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(5)}
              >
                News <ArrowDown color={`var(--pcBlue)`} />
                
                <ul className="p-0 m-0 unstyled-list Dis-mob FS-Disable single-menu">
                  <li className="first-kind"><a rel="noreferrer" href="https://www.pharmacompass.com/pharma-and-biotech-news" target="_blank">Pharma &amp; Biotech News #PharmaBuzz</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/weekly-news-recap-phispers" target="_blank">Weekly News Recap #Phispers</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/recent-products-approval-greenlight" target="_blank">Recent Product Approvals #GreenLight</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/quality-compliance-alert-warning" target="_blank">Quality Compliance Alerts #Warning!</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/latest-news-whats-happening" target="_blank">All News #Library</a></li>
                </ul>
              
              </li>
              <li
                className={`face-menu Dis-mob single-menu-ul ${
                  activeIndex === 6 ? "active-menu" : ""
                }`}
                onClick={() => showHideMenu(6)}
              >
                Blog <ArrowDown color={`var(--pcBlue)`} />
                <ul className="p-0 m-0 unstyled-list Dis-mob FS-Disable single-menu">
                  <li className="first-kind"><a rel="noreferrer" href="https://www.pharmacompass.com/blog" target="_blank">All Content by PharmaCompass &amp; Suppliers</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/radio-compass-blog" target="_blank">Data Compilation #PharmaFlow</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/pipeline-prospector-blog" target="_blank">Stock Recap #PipelineProspector</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/speak-pharma" target="_blank">Interview #SpeakPharma</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/video-supplier-spotlight" target="_blank">Video #SupplierSpotlight</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/video-pharmareel" target="_blank">Vlog #PharmaReel</a></li>
                  <li className=""><a rel="noreferrer" href="https://www.pharmacompass.com/supplier-blogs" target="_blank">Company Bio #AboutSupplier</a></li>
                  <li className="showdropmenu"><a rel="noreferrer" href="https://www.pharmacompass.com/pharma-blog" target="_blank">Service Bio #AboutCapabilities</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};
