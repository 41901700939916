import React from "react";
import { OutlinedButton } from "../components/ui-component/buttons/ButtonType";

const PRE_POPULATED_QUS = [
  {
    qestion: "USDMF Suppliers of Semaglutide?",
    contextName: "chat",
    type: "API",
  },
  {
    qestion: "Latest news on Sanofi?",
    contextName: "chat483",
    type: "483",
  },
  {
    qestion: "API price of Thiocolchicoside to Turkey in 2024?",
    contextName: "TRADE",
    type: "",
  },
  {
    qestion: "List of small molecule Phase 3 drugs in Oncology?",
    contextName: "chat_trade_data",
    type: "TRADE",
  },
  {
    qestion: "Drugs in development",
    contextName: "chat_drug_n_dev",
    type: "DRUG",
  },
  {
    qestion: "Latest News & insigts",
    contextName: "chat483",
    type: "483",
  },
];
const PrePopulatedQus = () => {
  const clickFunc = (qestion, contextName, type, chatId) => {
    console.log(qestion, contextName, type, chatId);
  };
  return (
    <>
      <div className="d-flex flex-column">
        <p className="my-3 mb-2 font-helvetica-condensed">PharmaCompass.Ai</p>
        <h1 className="mb-4 font-helvetica-condensed">
          What will you discover?
        </h1>
        <div className="pre-populated-question">
          {PRE_POPULATED_QUS?.map((item, index) => {
            return (
              <OutlinedButton
                key={index}
                buttonText={item?.qestion}
                clickFunc={() =>
                  clickFunc(item?.qestion, item?.contextName, item?.type)
                }
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default PrePopulatedQus;
